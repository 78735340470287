export const COLORs = {
  brandColorMain: "#0CC7FE",
  brandColorSub: "#FF358C",
  textColorDarken: "#000000",
  textColorRegular: "#333333",
  textColorLight: "#666666",
  textColorXLight: "#999999",
  textColorXXLight: "#CCCCCC",
  borderColor: "#666666",
  borderColorLight: "#999999",
  tagColorDefault: "#E5E5E5",
  hoverColorDefault: "#F4F4F4",
  iconColorActive: "#000000",
  iconColorInactive: "#666666",
  statusColorSuccess: "#5ECA63",
  statusColorError: "#E83B3B",
  statusColorDisable: "#CCCCCC",
  otherColorWhite: "#FFFFFF",
} as const;

export const FONT_SIZE = {
  heading: 3.2,
  largeText: 2.4,
  regularText: 1.6,
  smallText: 1.2,
} as const;

export const FONT_WEIGHT = {
  Regular: 400,
  Medium: 500,
  Bold: 600,
} as const;

export const ABILITY_JA = {
  READ_AUTHORITY_TEMPLATE: "権限テンプレート閲覧",
  CREATE_AUTHORITY_TEMPLATE: "権限テンプレート作成",
  UPDATE_AUTHORITY_TEMPLATE: "権限テンプレート更新",
  DESTROY_AUTHORITY_TEMPLATE: "権限テンプレート削除",
  INVITE_ADMIN: "管理画面ユーザー招待",
  UPDATE_ADMIN_AUTHORITY: "管理画面ユーザー権限更新",
  READ_USER: "ユーザー閲覧",
} as const;
