import React from "react";

const ErrorComponent = () => {
  return (
    <div>
      <h1>良きせぬエラーが発生しました</h1>
      <p>ブラウザをリロードしてください。</p>
    </div>
  );
};

export default ErrorComponent;
