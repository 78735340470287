import React from "react";

type Props = {
  src: string | ArrayBuffer;
  alt: string;
  height?: string;
  width?: string;
  radius?: string;
  clickHandler?: () => void;
  className?: string;
  style?: any;
};
const Image: React.FC<Props> = (props: Props) => {
  return (
    <img
      src={String(props.src)}
      alt={props.alt}
      height={props.height}
      width={props.width}
      onClick={() => props.clickHandler && props.clickHandler()}
      className={props.className}
      style={props.style}
    />
  );
};
export default Image;
