import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import AdminRes from "types/res/AdminRes";
import TypedHttpClient from "httpClient/TypedHttpClient";
import SchemaUtils from "httpClient/SchemaUtils";
import { normalize } from "normalizr";
import { RootState } from "store";
import { ABILITY_JA } from "shared/constant";

export interface State {
  status: "IDLE" | "LOADING" | "FAILED";
  entities: {
    admins: {
      [key: number]: AdminRes;
    };
  };
  result: number[];
}
const initialState: State = {
  status: "IDLE",
  entities: {
    admins: {},
  },
  result: [] as number[],
};

const AdminSchema = SchemaUtils.createEntity<AdminRes>("admins");
export const getAdmins = createAsyncThunk(
  "admin/list",
  async (_, { getState }) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.getArray(AdminRes, "/api/v1/admins", {
      headers: {
        ["access-token"]: headers.accessToken,
        client: headers.client,
        uid: headers.uid,
      },
    });
    const data = normalize(result, [AdminSchema]);
    return data;
  }
);
export const updateAdminAuthority = createAsyncThunk(
  "admin/updateAuthority",
  async (
    { id, abilities }: { id: number; abilities: KeyOf<typeof ABILITY_JA>[] },
    { dispatch, getState }
  ) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.patch(
      AdminRes,
      `/api/v1/admins/${id}/update_authority`,
      {
        adminAuthority: {
          abilities: abilities,
        },
      },
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      },
      false
    );
    if (result) dispatch(getAdmins());
  }
);

export const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.status = "IDLE";
        state.entities = action.payload.entities as any;
        state.result = action.payload.result;
      })
      .addCase(updateAdminAuthority.fulfilled, (state) => {
        state.status = "IDLE";
        toast.success("権限を更新しました");
      });
  },
});

export default admin.reducer;
