import React from "react";
import { useState } from "react";
import ManageAdmin from "./ManageAdmin";
import ManageAuthorityTemplate from "./ManageAuthorityTemplate";
import InviteModal from "./InviteModal";
import CreateAuthorityTemplateModal from "./CreateAuthorityTemplateModal";
import PageTitle from "components/PageTitle";
import Text from "components/Text";
import Button from "components/Button";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const AuthorityTemplate = () => {
  const [inviteModal, setInviteModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);

  return (
    <>
      <PageTitle text="権限管理" />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            text="管理者"
            size={FONT_SIZE.largeText}
            weight={FONT_WEIGHT.Bold}
            color={COLORs.textColorRegular}
          />
          <Button
            text="招待する"
            clickHandler={() => setInviteModal(true)}
            style={{ width: "200px" }}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <ManageAdmin />
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            text="権限テンプレート"
            size={FONT_SIZE.largeText}
            weight={FONT_WEIGHT.Bold}
            color={COLORs.textColorRegular}
          />
          <Button
            text="新規作成"
            clickHandler={() => setTemplateModal(true)}
            style={{ width: "200px" }}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <ManageAuthorityTemplate />
        </div>
      </div>
      <InviteModal
        display={inviteModal}
        closeHandler={() => setInviteModal(false)}
      />
      <CreateAuthorityTemplateModal
        display={templateModal}
        closeHandler={() => setTemplateModal(false)}
      />
    </>
  );
};

export default AuthorityTemplate;
