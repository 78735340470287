import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "components/Button";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 70%;
  max-width: 800px;
  border-radius: 4px;
  overflow-scrolling: touch;
  z-index: 1002;
`;
const Overlay = styled.div`
  position: fixed;
  top: -50%;
  left: -50%;
  z-index: 1001;
  width: 200vw;
  height: 200vh;
  cursor: pointer;
  background: #000;
  opacity: 0.2;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
`;
const Main = styled.div`
  max-height: 80vh;
  padding: 32px;
  overflow: scroll;
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
`;

type Props = {
  display: boolean;
  children: React.ReactNode;
  submitText: string;
  cancelText: string;
  submitHandler: () => void;
  closeHandler: () => void;
};
const Modal: React.FC<Props> = ({
  display,
  children,
  submitText,
  cancelText,
  submitHandler,
  closeHandler,
}: Props) => {
  useEffect(() => {
    const escHandler = (e: any) => {
      if (e.key === "Escape" || e.key === "Esc") closeHandler && closeHandler();
    };

    document.addEventListener("keydown", escHandler);
    return () => {
      document.removeEventListener("keydown", escHandler);
    };
  }, [closeHandler]);

  if (!display) return null;

  return (
    <>
      <Container>
        <Content>
          <Main>{children}</Main>
          <Footer>
            <Button text={cancelText} clickHandler={closeHandler} plain />
            <Button text={submitText} clickHandler={submitHandler} />
          </Footer>
        </Content>
      </Container>
      <Overlay
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          closeHandler();
        }}
      />
    </>
  );
};

export default Modal;
