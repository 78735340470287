import React from "react";
import styled from "styled-components";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const StyledLabel = styled.label<{ must: boolean }>`
  display: inline-flex;
  p {
    color: ${COLORs.textColorRegular};
    font-weight: ${FONT_WEIGHT.Bold};
  }
  ${(props) =>
    props.must &&
    `
    position: relative;
    &:after {
      content: '*';
      display: block;
      position: absolute;
      top: 0;
      right: -10px;
      color: red;
      font-size: 2rem;
      line-height: 22px;
    }
  `}
`;
type Props = {
  text: string;
  htmlFor?: string;
  must?: boolean;
  small?: boolean;
  color?: ValueOf<typeof COLORs> | string;
  weight?: ValueOf<typeof FONT_WEIGHT>;
  style?: Record<string, unknown>;
};
const Label: React.FC<Props> = (props: Props) => {
  return props.htmlFor ? (
    <StyledLabel
      htmlFor={props.htmlFor}
      must={!!props.must}
      style={props.style || {}}
    >
      <Text
        text={props.text}
        color={props.color || COLORs.textColorRegular}
        weight={props.weight || FONT_WEIGHT.Medium}
        size={props.small ? FONT_SIZE.smallText : FONT_SIZE.regularText}
      />
    </StyledLabel>
  ) : (
    <StyledLabel must={!!props.must} style={props.style || {}}>
      <Text
        text={props.text}
        color={props.color || COLORs.textColorRegular}
        weight={props.weight || FONT_WEIGHT.Medium}
        size={props.small ? FONT_SIZE.smallText : FONT_SIZE.regularText}
      />
    </StyledLabel>
  );
};
export default Label;
