import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import AuthorityTemplateRes from "types/res/AuthorityTemplateRes";
import TypedHttpClient from "httpClient/TypedHttpClient";
import SchemaUtils from "httpClient/SchemaUtils";
import { normalize } from "normalizr";
import { RootState } from "store";
import { ABILITY_JA } from "shared/constant";

export interface State {
  status: "IDLE" | "LOADING" | "FAILED";
  entities: {
    authorityTemplates: {
      [key: number]: AuthorityTemplateRes;
    };
  };
  result: number[];
}
const initialState: State = {
  status: "IDLE",
  entities: {
    authorityTemplates: {},
  },
  result: [] as number[],
};

const AuthorityTemplateSchema =
  SchemaUtils.createEntity<AuthorityTemplateRes>("authorityTemplates");
export const getAuthorityTemplates = createAsyncThunk(
  "authorityTemplate/list",
  async (_, { getState }) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.getArray(
      AuthorityTemplateRes,
      "/api/v1/authority_templates",
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      }
    );
    const data = normalize(result, [AuthorityTemplateSchema]);
    return data;
  }
);
export const createAuthorityTemplate = createAsyncThunk(
  "authorityTemplate/create",
  async (
    {
      params,
    }: { params: { name: string; abilities: KeyOf<typeof ABILITY_JA>[] } },
    { dispatch, getState }
  ) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.post(
      AuthorityTemplateRes,
      "/api/v1/authority_templates",
      {
        authorityTemplate: params,
      },
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      },
      false
    );
    if (result) {
      dispatch(getAuthorityTemplates());
      return true;
    }
  }
);
export const updateAuthorityTemplate = createAsyncThunk(
  "authorityTemplate/updateAuthority",
  async (
    { id, abilities }: { id: number; abilities: KeyOf<typeof ABILITY_JA>[] },
    { dispatch, getState }
  ) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.patch(
      AuthorityTemplateRes,
      `/api/v1/authority_templates/${id}`,
      {
        authorityTemplate: {
          abilities: abilities,
        },
      },
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      },
      false
    );
    if (result) dispatch(getAuthorityTemplates());
  }
);
export const deleteAuthorityTemplate = createAsyncThunk(
  "authorityTemplate/delete",
  async ({ id }: { id: number }, { getState }) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.delete(
      AuthorityTemplateRes,
      `/api/v1/authority_templates/${id}`,
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      },
      false
    );
    if (result) {
      return { id: id };
    }
  }
);

export const authorityTemplate = createSlice({
  name: "authorityTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthorityTemplates.fulfilled, (state, action) => {
        state.status = "IDLE";
        state.entities = action.payload.entities as any;
        state.result = action.payload.result;
      })
      .addCase(createAuthorityTemplate.fulfilled, (state) => {
        state.status = "IDLE";
        toast.success("権限テンプレートを作成しました");
      })
      .addCase(updateAuthorityTemplate.fulfilled, (state) => {
        state.status = "IDLE";
        toast.success("権限テンプレートを更新しました");
      })
      .addCase(deleteAuthorityTemplate.fulfilled, (state, action) => {
        state.status = "IDLE";
        if (action.payload) {
          const id = action.payload.id;
          delete state.entities.authorityTemplates[id];
          state.result = state.result.filter((r) => r !== id);
        }
        toast.success("権限テンプレートを削除しました");
      });
  },
});

export default authorityTemplate.reducer;
