import React from "react";
import Popup from "reactjs-popup";
import styled from "styled-components";

const Container = styled.div`
  padding: 8px;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
`;
type Props = {
  trigger: JSX.Element;
  children: React.ReactNode;
  position?: PopupPosition | PopupPosition[];
  on?: PopupEventType | PopupEventType[];
  keepTooltipInside?: boolean | string;
  offsetX?: number;
  offsetY?: number;
  mouseEnterDelay?: number;
  nested?: boolean;
  closeOnContentClick?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  defaultOpen?: boolean;
  style?: Record<string, unknown>;
};
const SmartPopup: React.FC<Props> = (props: Props) => {
  const handleClose = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    close: () => void
  ) => {
    close();
  };

  // https://github.com/yjose/reactjs-popup/issues/310
  // https://github.com/yjose/reactjs-popup/issues/315
  const body: any = (close: () => void) => {
    return (
      <Container
        onClick={(e) => props.closeOnContentClick && handleClose(e, close)}
        style={props.style || {}}
      >
        {props.children}
      </Container>
    );
  };

  return (
    <Popup
      trigger={props.trigger}
      position={props.position || "bottom center"}
      on={props.on || "hover"}
      keepTooltipInside={props.keepTooltipInside || true}
      offsetX={props.offsetX || 0}
      offsetY={props.offsetY || 0}
      mouseEnterDelay={props.mouseEnterDelay || 100}
      arrow={false}
      onOpen={props.onOpen}
      onClose={props.onClose}
      nested={props.nested || false}
      contentStyle={{ zIndex: 10002, maxHeight: "300px" }}
      overlayStyle={{ zIndex: 10001 }}
      {...(props.defaultOpen ? { open: true } : {})}
    >
      {body}
    </Popup>
  );
};
export default SmartPopup;
