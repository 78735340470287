import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import { Definition } from "./AuthorityTemplateRes";

export default class AdminRes extends BaseRes {
  @Expose() readonly provider: string;
  @Expose() readonly uid: string;
  @Expose() readonly name: string;
  @Expose() readonly email: string;
  @Expose() readonly isMaster: boolean;
  @Type(() => Definition)
  @Expose()
  authority: Definition;
}
