import React from "react";
import styled from "styled-components";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { signOut } from "httpClient/auth";
import { useAppSelector } from "store";
import Text from "components/Text";
import Image from "components/Image";
import Svg from "components/Svg";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";
import logoIcon from "assets/images/station-logo.png";
import { ReactComponent as homeIcon } from "assets/images/home.svg";
import { ReactComponent as bellIcon } from "assets/images/bell.svg";
import { ReactComponent as usersIcon } from "assets/images/users.svg";
import { ReactComponent as screenIcon } from "assets/images/screen.svg";
import { ReactComponent as authorityIcon } from "assets/images/authority.svg";
import { ReactComponent as logoutIcon } from "assets/images/logout.svg";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
const SidebarContainer = styled.div`
  width: 300px;
  min-width: 300px;
  height: 100%;
  background-color: ${COLORs.otherColorWhite};
  border-right: solid 1px #ddd;
`;
const SidebarInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 24px 24px;
`;
const SidebarTop = styled.div``;
const SidebarBottom = styled.div``;
const MenuList = styled.ul`
  margin-top: 16px;
`;
const MenuItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 10px;
  border-radius: 8px;
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
    background-color: ${COLORs.hoverColorDefault};
    svg.fill {
      path {
        fill: ${COLORs.textColorDarken};
      }
    }
    svg.stroke {
      path {
        stroke: ${COLORs.textColorDarken};
      }
    }
    p {
    color: ${COLORs.textColorDarken};
    }
  `}
  &:not(:first-child) {
    margin-top: 8px;
  }
  &:hover {
    background-color: ${COLORs.hoverColorDefault};
    svg.fill {
      path {
        fill: ${COLORs.textColorDarken};
      }
    }
    svg.stroke {
      path {
        stroke: ${COLORs.textColorDarken};
      }
    }
    p {
      color: ${COLORs.textColorDarken};
    }
  }
`;
const MainContainer = styled.main`
  flex-grow: 1;
  height: 100%;
  background-color: #fafafa;
  padding: 40px;
  overflow-y: scroll;
`;
const Main: React.FC = () => {
  const navigate = useNavigate();
  const { accessToken, client, uid } = useAppSelector(
    (state) => state.auth.authHeaders
  );
  const { pathname } = useLocation();

  const handleSignout = async () => {
    try {
      const res = await signOut(accessToken, client, uid);
      if (res) {
        navigate("/");
        toast.success("ログアウトしました");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      <SidebarContainer>
        <SidebarInner>
          <SidebarTop>
            <Image src={logoIcon} alt="logo" style={{ height: "40px" }} />
            <MenuList>
              {[
                {
                  key: "home",
                  icon: homeIcon,
                  text: "ホーム",
                  path: "/app",
                  svgType: "fill",
                },
                {
                  key: "notification",
                  icon: bellIcon,
                  text: "お知らせ管理",
                  path: "/app/notification",
                  svgType: "fill",
                },
                {
                  key: "user",
                  icon: usersIcon,
                  text: "ユーザー管理",
                  path: "/app/user",
                  svgType: "stroke",
                },
                {
                  key: "application",
                  icon: screenIcon,
                  text: "申し込み画面発行",
                  path: "/app/application",
                  svgType: "stroke",
                },
                {
                  key: "authority_templates",
                  icon: authorityIcon,
                  text: "権限管理",
                  path: "/app/authority_templates",
                  svgType: "fill",
                },
              ].map((item) => {
                return (
                  <MenuItem
                    key={item.key}
                    active={item.path === pathname}
                    onClick={() => navigate(item.path)}
                  >
                    <Svg
                      component={item.icon}
                      height="24px"
                      width="24px"
                      className={item.svgType}
                      {...(item.svgType === "fill"
                        ? { fillColor: COLORs.textColorXLight }
                        : { strokecolor: COLORs.textColorXLight })}
                    />
                    <Text
                      text={item.text}
                      color={COLORs.textColorXLight}
                      weight={FONT_WEIGHT.Bold}
                      size={FONT_SIZE.regularText}
                      style={{ marginLeft: "8px" }}
                    />
                  </MenuItem>
                );
              })}
            </MenuList>
          </SidebarTop>
          <SidebarBottom>
            <MenuItem onClick={handleSignout}>
              <Svg
                component={logoutIcon}
                fillcolor={COLORs.textColorXLight}
                height="24px"
                width="24px"
                className="fill"
              />
              <Text
                text="ログアウト"
                color={COLORs.textColorXLight}
                weight={FONT_WEIGHT.Bold}
                size={FONT_SIZE.regularText}
                style={{ marginLeft: "8px" }}
              />
            </MenuItem>
          </SidebarBottom>
        </SidebarInner>
      </SidebarContainer>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </Container>
  );
};
export default Main;
