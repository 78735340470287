import React, { useState, useEffect } from "react";
import { useAppDispatch } from "store";
import styled from "styled-components";
import Modal from "components/Modal";
import Label from "components/Label";
import InputText from "components/InputText";
import InputCheckbox from "components/InputCheckbox";
import { createAuthorityTemplate } from "slices/authorityTemplate";
import { ABILITY_JA } from "shared/constant";
import { addItem, removeItem } from "shared/utility";

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;
const Item = styled.li`
  width: 50%;
  margin-top: 16px;
  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
  }
`;
const ABILITIES = Object.keys(ABILITY_JA) as KeyOf<typeof ABILITY_JA>[];
type Props = {
  display: boolean;
  closeHandler: () => void;
};
const CreateAuthorityTemplateModal: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [abilities, setAbilities] = useState<KeyOf<typeof ABILITY_JA>[]>([]);

  useEffect(() => {
    setAbilities(ABILITIES);
  }, []);

  const handleReset = () => {
    setName("");
    setAbilities(ABILITIES);
  };

  const handleSubmit = async () => {
    if (!name) {
      alert("テンプレート名を入力してください");
      return;
    }
    const result = await dispatch(
      createAuthorityTemplate({
        params: {
          name: name,
          abilities: abilities,
        },
      })
    );
    if (result.payload) {
      handleReset();
      props.closeHandler();
    }
  };

  return (
    <Modal
      display={props.display}
      submitText="確定する"
      cancelText="キャンセル"
      submitHandler={handleSubmit}
      closeHandler={props.closeHandler}
    >
      <div>
        <div>
          <Label text="テンプレート名" htmlFor="name" must />
          <InputText
            name="name"
            value={name}
            changeHandler={(e) => setName(e.target.value)}
            placeholder="例. 管理者、メンバー、インターン、開発者"
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <Label text="権限詳細" htmlFor="detail" must />
          <List>
            {ABILITIES.map((ability) => {
              const hasAbility = abilities.some((ab) => ab === ability);
              return (
                <Item key={ability}>
                  <InputCheckbox
                    name={ability}
                    checked={hasAbility}
                    changeHandler={() => {
                      const result = hasAbility
                        ? removeItem(abilities, ability)
                        : addItem(abilities, ability);
                      setAbilities(result);
                    }}
                    text={ABILITY_JA[ability]}
                  />
                </Item>
              );
            })}
          </List>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAuthorityTemplateModal;
