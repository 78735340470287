import React, { useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "store";
import { getAdmins, updateAdminAuthority } from "slices/admin";
import Table from "components/Table";
import Text from "components/Text";
import InputCheckbox from "components/InputCheckbox";
import SmartPopup from "components/SmartPopup";
import { COLORs, FONT_SIZE, FONT_WEIGHT, ABILITY_JA } from "shared/constant";
import { addItem, removeItem } from "shared/utility";

const Trigger = styled.div`
  display: inline-flex;
  margin-top: 8px;
  cursor: pointer;
`;
const List = styled.ul`
  min-width: 200px;
  padding: 4px 6px;
`;
const Item = styled.li`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;
const ABILITIES = Object.keys(ABILITY_JA) as KeyOf<typeof ABILITY_JA>[];
const ManageAdmin = () => {
  const dispatch = useAppDispatch();
  const _admins = useAppSelector((state) => state.admin.entities.admins);
  const _adminsSort = useAppSelector((state) => state.admin.result);
  const admins = (_adminsSort || []).map((id) => _admins[id]);
  const _adminInvitations = useAppSelector(
    (state) => state.adminInvitation.entities.adminInvitations
  );
  const _adminInvitationsSort = useAppSelector(
    (state) => state.adminInvitation.result
  );
  const adminInvitations = (_adminInvitationsSort || []).map(
    (id) => _adminInvitations[id]
  );

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);

  const toggleAdminAbility = (
    adminId: number,
    ability: KeyOf<typeof ABILITY_JA>
  ) => {
    const abilities = _admins[adminId].authority.abilities;
    const params = abilities.includes(ability)
      ? removeItem(abilities, ability)
      : addItem(abilities, ability);
    dispatch(updateAdminAuthority({ id: adminId, abilities: params }));
  };

  const trs = admins.map((admin) => {
    const tds = [
      {
        key: "id",
        component: (
          <Text
            text={`${admin.id}`}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "isMaster",
        component: (
          <Text
            text={admin.isMaster ? "Yes" : "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "name",
        component: (
          <Text
            text={admin.name || "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "email",
        component: (
          <Text
            text={admin.email || "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      ...ABILITIES.map((ability) => {
        const hasAbility = admin.authority.abilities.some(
          (ab) => ab === ability
        );
        return {
          key: ability,
          component: (
            <InputCheckbox
              name={`${admin.id}_${ability}`}
              checked={hasAbility}
              changeHandler={() => toggleAdminAbility(admin.id, ability)}
            />
          ),
        };
      }),
    ];
    return {
      key: admin.id,
      tds: tds,
    };
  });
  return (
    <>
      <Table
        ths={[
          { key: "id", name: "ID" },
          { key: "isMaster", name: "マスター" },
          { key: "name", name: "ユーザー名" },
          { key: "email", name: "メールアドレス" },
          ...ABILITIES.map((ability) => {
            return {
              key: ability,
              name: ABILITY_JA[ability],
            };
          }),
        ]}
        trs={trs}
        styleProps={[
          {
            key: "id",
            style: { minWidth: "40px", maxWidth: "40px" },
          },
          {
            key: "isMaster",
            style: { minWidth: "80px", maxWidth: "80px" },
          },
          {
            key: "name",
            style: { minWidth: "160px", maxWidth: "160px" },
          },
          {
            key: "email",
            style: { minWidth: "240px", maxWidth: "240px" },
          },
          ...ABILITIES.map((ability) => {
            return {
              key: ability,
              style: { minWidth: "220px", maxWidth: "220px" },
            };
          }),
        ]}
      />
      {adminInvitations.length > 0 && (
        <SmartPopup
          trigger={
            <Trigger>
              <Text
                text={`※招待中ユーザーあり（${adminInvitations.length}件）`}
                color={COLORs.textColorRegular}
                weight={FONT_WEIGHT.Medium}
                size={FONT_SIZE.regularText}
                style={{ marginLeft: "16px" }}
              />
            </Trigger>
          }
          position="bottom left"
          on="hover"
          offsetX={16}
        >
          <List>
            {adminInvitations.map((invitation) => {
              return (
                <Item key={invitation.id}>
                  <Text
                    text={invitation.email}
                    color={COLORs.textColorRegular}
                    weight={FONT_WEIGHT.Medium}
                    size={FONT_SIZE.regularText}
                  />
                </Item>
              );
            })}
          </List>
        </SmartPopup>
      )}
    </>
  );
};

export default ManageAdmin;
