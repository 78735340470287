import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "pages/Signin";
import Signup from "pages/Signup";
import Home from "pages/Home";
import Notification from "pages/Notification";
import User from "pages/User";
import Application from "pages/Application";
import AuthorityTemplate from "pages/AuthorityTemplate";
import Main from "templates/Main";
import NotFound from "pages/NotFound";
import { getCurrentUser } from "httpClient/auth";
import { setAdmin } from "slices/auth";
import { useAppDispatch, useAppSelector } from "store";

const App = () => {
  const dispatch = useAppDispatch();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const { accessToken, client, uid } = useAppSelector(
    (state) => state.auth.authHeaders
  );

  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser(accessToken, client, uid);
      if (res?.data.isLogin) {
        setIsSignedIn(true);
        dispatch(setAdmin(res.data.admin));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (accessToken && client && uid) {
      handleGetCurrentUser();
    }
  }, [accessToken, client, uid]);

  if (!isSignedIn) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/sign_up" element={<Signup />} />
          <Route path="/*" element={<Signin />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/sign_up" element={<Signup />} />
        <Route path="app" element={<Main />}>
          <Route path="" element={<Home />} />
          <Route path="notification" element={<Notification />} />
          <Route path="user" element={<User />} />
          <Route path="application" element={<Application />} />
          <Route path="authority_templates" element={<AuthorityTemplate />} />
        </Route>
        <Route path="/*/" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
