import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px;
`;
const Inner = styled.div`
  margin: 0 auto;
  max-width: 480px;
`;
type Props = {
  children: React.ReactNode;
};
const Simple: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Inner>{props.children}</Inner>
    </Container>
  );
};
export default Simple;
