import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Container = styled.button<{
  danger: boolean;
  warning: boolean;
  plain: boolean;
  disable: boolean;
}>`
  appearance: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.danger
      ? COLORs.statusColorError
      : props.warning
      ? COLORs.statusColorError
      : props.plain
      ? COLORs.otherColorWhite
      : COLORs.brandColorMain};
  height: 62px;
  min-width: 40px;
  padding: 5px 16px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.danger
        ? COLORs.statusColorError
        : props.warning
        ? COLORs.statusColorError
        : props.plain
        ? COLORs.otherColorWhite
        : COLORs.brandColorMain};
    opacity: 0.8;
  }
  ${(props) =>
    props.plain &&
    `
    border: solid 1px ${COLORs.borderColor};
  `}
  ${(props) =>
    props.disable &&
    `
    background-color: ${COLORs.statusColorDisable};
    pointer-events: none;
    cursor: not-allowed;
    &:hover {
      background-color: ${COLORs.statusColorDisable};
    }
  `}
`;

type Props = {
  text: string;
  clickHandler?: any;
  danger?: boolean;
  warning?: boolean;
  plain?: boolean;
  disable?: boolean;
  disablable?: boolean;
  style?: Record<string, unknown>;
  fontSize?: ValueOf<typeof FONT_SIZE>;
  fontWeight?: ValueOf<typeof FONT_WEIGHT>;
  textColor?: ValueOf<typeof COLORs>;
};
const Button: React.FC<Props> = (props: Props) => {
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(!!props.disable);
  }, [props.disable]);

  const handleClick = (e: any) => {
    if (!props.clickHandler || disable) return;

    if (props.disablable) {
      setDisable(true);
      setTimeout(() => setDisable(false), 2000);
    }
    props.clickHandler(e);
  };

  return (
    <Container
      danger={!!props.danger}
      warning={!!props.warning}
      plain={!!props.plain}
      disable={disable}
      style={props.style}
      onClick={handleClick}
    >
      <Text
        text={props.text}
        weight={props.fontWeight || FONT_WEIGHT.Bold}
        size={props.fontSize || FONT_SIZE.largeText}
        style={{
          color:
            props.textColor || props.plain
              ? COLORs.textColorRegular
              : COLORs.otherColorWhite,
        }}
      />
    </Container>
  );
};
export default Button;
