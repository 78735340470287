import { schema, Schema } from "normalizr";

export default class SchemaUtils {
  private constructor() {
    //  do nothing
  }
  static createEntity<T>(name: string, definition?: Schema): schema.Entity<T> {
    return new schema.Entity(name, definition, { idAttribute: "id" });
  }
}
