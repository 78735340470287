import React from "react";
import styled from "styled-components";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Container = styled.div`
  background-color: ${COLORs.otherColorWhite};
  width: 100%;
  padding: 8px 14px 24px;
  overflow-x: scroll;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const TableHeaderCell = styled.div`
  padding: 4px 0 4px 8px;
  white-space: nowrap;
`;
const TableBody = styled.div`
  display: table-row;
  width: 100%;
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f6f7;
  width: 100%;
  padding: 8px;
  &:not(:first-child) {
    margin-top: 16px;
  }
`;
const TableRowCell = styled.div`
  position: relative;
  width: 100%;
`;
type Props = {
  ths: {
    key: string;
    name: string;
  }[];
  trs: {
    key: string | number;
    tds: {
      key: string;
      component: JSX.Element;
    }[];
  }[];
  styleProps: {
    key: string;
    style: Record<string, unknown>;
  }[];
};
const Table: React.FC<Props> = (props: Props) => {
  const ths = props.ths.map((th) => {
    const style = props.styleProps.find((s) => s.key === th.key)?.style || {};
    return (
      <TableHeaderCell key={th.key} style={style}>
        <Text
          text={th.name}
          color={COLORs.textColorRegular}
          weight={FONT_WEIGHT.Bold}
          size={FONT_SIZE.regularText}
        />
      </TableHeaderCell>
    );
  });

  const trs = props.trs.map((tr, index) => {
    const tds = tr.tds.map((td) => {
      const style = props.styleProps.find((s) => s.key === td.key)?.style || {};
      return (
        <TableRowCell key={`${tr.key || index}_${td.key}`} style={style}>
          {td.component}
        </TableRowCell>
      );
    });
    return <TableRow key={tr.key}>{tds}</TableRow>;
  });

  return (
    <Container>
      <TableHeader>{ths}</TableHeader>
      <TableBody>
        {props.trs.length === 0 && (
          <Text
            text="データがありません"
            color={COLORs.textColorXLight}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.regularText}
            style={{ padding: "12px 6px" }}
          />
        )}
        {trs}
      </TableBody>
    </Container>
  );
};
export default Table;
