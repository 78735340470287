import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  getAuthorityTemplates,
  updateAuthorityTemplate,
  deleteAuthorityTemplate,
} from "slices/authorityTemplate";
import Table from "components/Table";
import Text from "components/Text";
import Svg from "components/Svg";
import InputCheckbox from "components/InputCheckbox";
import { COLORs, FONT_SIZE, FONT_WEIGHT, ABILITY_JA } from "shared/constant";
import { addItem, removeItem } from "shared/utility";
import { ReactComponent as trashIcon } from "assets/images/trash.svg";

const ABILITIES = Object.keys(ABILITY_JA) as KeyOf<typeof ABILITY_JA>[];
const ManageAuthorityTemplate = () => {
  const dispatch = useAppDispatch();
  const _authorityTemplates = useAppSelector(
    (state) => state.authorityTemplate.entities.authorityTemplates
  );
  const _authorityTemplatesSort = useAppSelector(
    (state) => state.authorityTemplate.result
  );
  const authorityTemplates = (_authorityTemplatesSort || []).map(
    (id) => _authorityTemplates[id]
  );

  useEffect(() => {
    dispatch(getAuthorityTemplates());
  }, [dispatch]);

  const toggleTemplateAbility = (
    templateId: number,
    ability: KeyOf<typeof ABILITY_JA>
  ) => {
    const abilities = _authorityTemplates[templateId].definition.abilities;
    const params = abilities.includes(ability)
      ? removeItem(abilities, ability)
      : addItem(abilities, ability);
    dispatch(updateAuthorityTemplate({ id: templateId, abilities: params }));
  };
  const handleDelete = (templateId: number) => {
    if (!window.confirm("権限テンプレートを削除します。\nよろしいですか？"))
      return;
    dispatch(deleteAuthorityTemplate({ id: templateId }));
  };

  const trs = authorityTemplates.map((template) => {
    const tds = [
      {
        key: "id",
        component: (
          <Text
            text={`${template.id}`}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "name",
        component: (
          <Text
            text={template.name || "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      ...ABILITIES.map((ability) => {
        const hasAbility = template.definition.abilities.some(
          (ab) => ab === ability
        );
        return {
          key: ability,
          component: (
            <InputCheckbox
              name={`${template.id}_${ability}`}
              checked={hasAbility}
              changeHandler={() => toggleTemplateAbility(template.id, ability)}
            />
          ),
        };
      }),
      {
        key: "action",
        component: (
          <div
            onClick={() => handleDelete(template.id)}
            style={{ cursor: "pointer" }}
          >
            <Svg
              component={trashIcon}
              height="24px"
              width="24px"
              strokecolor={COLORs.statusColorError}
            />
          </div>
        ),
      },
    ];
    return {
      key: template.id,
      tds: tds,
    };
  });
  return (
    <Table
      ths={[
        { key: "id", name: "ID" },
        { key: "name", name: "テンプレート名" },
        ...ABILITIES.map((ability) => {
          return {
            key: ability,
            name: ABILITY_JA[ability],
          };
        }),
        { key: "action", name: "アクション" },
      ]}
      trs={trs}
      styleProps={[
        {
          key: "id",
          style: { minWidth: "40px", maxWidth: "40px" },
        },
        {
          key: "name",
          style: { minWidth: "160px", maxWidth: "160px" },
        },
        ...ABILITIES.map((ability) => {
          return {
            key: ability,
            style: { minWidth: "220px", maxWidth: "220px" },
          };
        }),
        {
          key: "action",
          style: { minWidth: "80px", maxWidth: "80px" },
        },
      ]}
    />
  );
};

export default ManageAuthorityTemplate;
