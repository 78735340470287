import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export interface State {
  status: "IDLE" | "LOADING" | "FAILED";
}
const initialState: State = {
  status: "IDLE",
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "LOADING";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state) => {
          state.status = "FAILED";
          toast.error("エラーが発生しました");
        }
      );
  },
});

export default common.reducer;
