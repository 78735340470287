import React from "react";
import styled from "styled-components";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Title = styled.h1`
  color: ${COLORs.textColorDarken};
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: ${FONT_SIZE.heading}rem;
`;
type Props = {
  text: string;
};
const PageTitle: React.FC<Props> = (props: Props) => {
  return <Title>{props.text}</Title>;
};
export default PageTitle;
