import applyCaseMiddleware from "axios-case-converter";
import axios from "axios";
import { SignUpParams, SignInParams } from "types/req/auth";

const axiosClient = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "Content-Type": "application/json",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "Access-Control-Allow-Origin": process.env.REACT_APP_HOST as string,
      Accept: "application/json",
    },
    withCredentials: true,
  }),
  {
    ignoreHeaders: true,
  }
);

export const signUp = (params: SignUpParams) => {
  return axiosClient.post("auth", {
    ...params,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirm_success_url: "https://station.space",
  });
};

export const signIn = (params: SignInParams) => {
  return axiosClient.post("auth/sign_in", params);
};

export const signOut = (accessToken: string, client: string, uid: string) => {
  return axiosClient.delete("auth/sign_out", {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "access-token": accessToken,
      client: client,
      uid: uid,
    },
  });
};

export const getCurrentUser = (
  accessToken: string,
  client: string,
  uid: string
) => {
  return axiosClient.get("/api/v1/currents/user", {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "access-token": accessToken,
      client: client,
      uid: uid,
    },
  });
};
