import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import { signIn } from "httpClient/auth";
import { setAuthHeaders } from "slices/auth";
import { useAppDispatch } from "store";
import Simple from "templates/Simple";
import Label from "components/Label";
import InputEmail from "components/InputEmail";
import InputPassword from "components/InputPassword";
import Button from "components/Button";
import Text from "components/Text";
import TextLink from "components/TextLink";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Form = styled.form``;
const Item = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
`;
const Aside = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
const Signin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await signIn({
        email: email,
        password: password,
      });
      if (res) {
        const headers = res.headers;
        dispatch(
          setAuthHeaders({
            accessToken: headers["access-token"],
            client: headers.client,
            uid: headers.uid,
          })
        );
        navigate("/app");
        toast.success("ログインしました");
      }
    } catch (err) {
      toast.error("ログインに失敗しました");
    }
  };

  return (
    <Simple>
      <Text
        text="ログイン"
        color={COLORs.textColorRegular}
        size={FONT_SIZE.heading}
        weight={FONT_WEIGHT.Bold}
      />
      <Form onSubmit={handleSignin}>
        <Item>
          <Label text="メールアドレス" htmlFor="email" must />
          <div>
            <InputEmail
              name="email"
              value={email}
              placeholder="メールアドレス"
              changeHandler={(e) => setEmail(e.target.value)}
            />
          </div>
        </Item>
        <Item>
          <Label text="パスワード" htmlFor="password" must />
          <div>
            <InputPassword
              name="password"
              value={password}
              placeholder="パスワード"
              changeHandler={(e) => setPassword(e.target.value)}
            />
          </div>
        </Item>
        <Item>
          <Button
            text="ログイン"
            clickHandler={handleSignin}
            style={{ width: "100%" }}
          />
        </Item>
      </Form>
      <Aside>
        <Link to="/sign_up">
          <TextLink text="新規登録はこちらから" />
        </Link>
      </Aside>
      <div style={{ marginTop: "40px" }}>
        <div>デバッグ用</div>
        <Link to="/app">
          <TextLink text="管理画面へ" />
        </Link>
      </div>
    </Simple>
  );
};

export default Signin;
