import React from "react";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Application = () => {
  return (
    <Text
      text="WIP | 申し込み画面発行"
      color={COLORs.textColorXLight}
      weight={FONT_WEIGHT.Bold}
      size={FONT_SIZE.largeText}
    />
  );
};

export default Application;
