import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthHeaderState {
  accessToken: string;
  client: string;
  uid: string;
}
interface AdminState {
  id: number;
  uid: string;
  email: string;
  name: string;
  authority: {
    abilities: string[];
  };
  isMaster: boolean;
  provider: string;
}
export interface State {
  authHeaders: AuthHeaderState;
  admin: AdminState | undefined;
}
const initialState: State = {
  authHeaders: {
    accessToken: "",
    client: "",
    uid: "",
  },
  admin: undefined,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthHeaders(state: State, action: PayloadAction<AuthHeaderState>) {
      state.authHeaders = action.payload;
    },
    setAdmin(state: State, action: PayloadAction<AdminState>) {
      state.admin = action.payload;
    },
  },
});

export const { setAuthHeaders, setAdmin } = auth.actions;
export default auth.reducer;
