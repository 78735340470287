import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRes from "types/res/UserRes";
import TypedHttpClient from "httpClient/TypedHttpClient";
import SchemaUtils from "httpClient/SchemaUtils";
import { normalize } from "normalizr";
import { RootState } from "store";

export interface State {
  status: "IDLE" | "LOADING" | "FAILED";
  entities: {
    users: {
      [key: number]: UserRes;
    };
  };
  result: number[];
}
const initialState: State = {
  status: "IDLE",
  entities: {
    users: {},
  },
  result: [] as number[],
};

const UserSchema = SchemaUtils.createEntity<UserRes>("users");
export const getUsers = createAsyncThunk(
  "user/list",
  async (_, { getState }) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.getArray(UserRes, "/api/v1/users", {
      headers: {
        ["access-token"]: headers.accessToken,
        client: headers.client,
        uid: headers.uid,
      },
    });
    const data = normalize(result, [UserSchema]);
    return data;
  }
);

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = "IDLE";
        state.entities = action.payload.entities as any;
        state.result = action.payload.result;
      });
  },
});

export default user.reducer;
