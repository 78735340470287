import React from "react";
import styled from "styled-components";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Link = styled.span<{ ellipsis: boolean }>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  ${(props) => props.ellipsis && "overflow: hidden;"}

  &:hover {
    p {
      font-weight: ${FONT_WEIGHT.Bold};
    }
  }
`;

type Props = {
  text: string;
  clickHandler?: any;
  danger?: boolean;
  ellipsis?: boolean;
  style?: Record<string, unknown>;
};
const TextLink: React.FC<Props> = (props: Props) => {
  return (
    <Link
      onClick={props.clickHandler}
      ellipsis={!!props.ellipsis}
      style={props.style}
    >
      <Text
        text={props.text}
        weight={FONT_WEIGHT.Regular}
        size={FONT_SIZE.smallText}
        style={
          props.ellipsis
            ? {
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: props.danger
                  ? COLORs.statusColorError
                  : COLORs.brandColorMain,
              }
            : {
                color: props.danger
                  ? COLORs.statusColorError
                  : COLORs.brandColorMain,
              }
        }
      />
    </Link>
  );
};
export default TextLink;
