import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import AdminInvitationRes from "types/res/AdminInvitationRes";
import TypedHttpClient from "httpClient/TypedHttpClient";
import SchemaUtils from "httpClient/SchemaUtils";
import { normalize } from "normalizr";
import { RootState } from "store";
import { ABILITY_JA } from "shared/constant";

export interface State {
  status: "IDLE" | "LOADING" | "FAILED";
  entities: {
    adminInvitations: {
      [key: number]: AdminInvitationRes;
    };
  };
  result: number[];
}
const initialState: State = {
  status: "IDLE",
  entities: {
    adminInvitations: {},
  },
  result: [] as number[],
};

const AdminInvitationSchema =
  SchemaUtils.createEntity<AdminInvitationRes>("adminInvitations");
export const getAdminInvitations = createAsyncThunk(
  "adminInvitaion/list",
  async (_, { getState }) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.getArray(
      AdminInvitationRes,
      "/api/v1/admin_invitations",
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      }
    );
    const data = normalize(result, [AdminInvitationSchema]);
    return data;
  }
);
export const createAdminInvitation = createAsyncThunk(
  "adminInvitation/create",
  async (
    {
      email,
      expiredAt,
      abilities,
    }: {
      email: string;
      expiredAt: dayjs.ConfigType;
      abilities: KeyOf<typeof ABILITY_JA>[];
    },
    { dispatch, getState }
  ) => {
    const headers = (getState() as RootState).auth.authHeaders;
    const result = await TypedHttpClient.post(
      AdminInvitationRes,
      `/api/v1/admin_invitations`,
      {
        adminInvitation: {
          email: email,
          expiredAt: expiredAt,
          authority: {
            abilities: abilities,
          },
        },
      },
      {
        headers: {
          ["access-token"]: headers.accessToken,
          client: headers.client,
          uid: headers.uid,
        },
      },
      false
    );
    if (result) {
      dispatch(getAdminInvitations());
      return true;
    }
  }
);

export const adminInvitation = createSlice({
  name: "adminInvitation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminInvitations.fulfilled, (state, action) => {
        state.status = "IDLE";
        state.entities = action.payload.entities as any;
        state.result = action.payload.result;
      })
      .addCase(createAdminInvitation.fulfilled, (state) => {
        state.status = "IDLE";
        toast.success("管理者を招待しました");
      });
  },
});

export default adminInvitation.reducer;
