// class-transformer's requirement
import "reflect-metadata";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
import store from "store";
import App from "App";
import reportWebVitals from "reportWebVitals";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, { BugsnagPluginReactResult } from "@bugsnag/plugin-react";
import ErrorComponent from "pages/ErrorComponent";

Bugsnag.start({
  apiKey: "6a313ad6660ef7b0faeece5d9ebfa5b8",
  enabledReleaseStages: ["production", "development"],
  releaseStage: process.env.REACT_APP_APPLICATION_ENV,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = (Bugsnag.getPlugin("react") as BugsnagPluginReactResult).createErrorBoundary(React);

const persistor = persistStore(store);
const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
          <Toaster position="top-center" />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
