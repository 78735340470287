import React from "react";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Home = () => {
  return (
    <Text
      text="WIP | ホーム"
      color={COLORs.textColorXLight}
      weight={FONT_WEIGHT.Bold}
      size={FONT_SIZE.largeText}
    />
  );
};

export default Home;
