import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import { Definition } from "./AuthorityTemplateRes";

export default class AdminInvitationRes extends BaseRes {
  @Expose() readonly email: string;
  @Expose() readonly expiredAt: string;
  @Expose() readonly isValid: boolean;
  @Type(() => Definition)
  @Expose()
  authority: Definition;
}
