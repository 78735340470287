import React from "react";
import styled from "styled-components";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Input = styled.input`
  width: 100%;
  padding: 10px 14px;
  font-size: ${FONT_SIZE.regularText}rem;
  font-weight: ${FONT_WEIGHT.Regular};
  color: ${COLORs.textColorRegular};
  cursor: pointer;
  background-color: ${COLORs.otherColorWhite};
  border: solid 1px ${COLORs.borderColor};
  border-radius: 6px;

  &::placeholder {
    color: ${COLORs.textColorXXLight};
  }

  &:focus {
    outline: none;
  }
`;

type Props = {
  id?: string;
  className?: string;
  name: string;
  value?: any;
  defaultValue?: any;
  placeholder?: string;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clickHandler?: any;
  blurHandler?: any;
  autoFocus?: boolean;
  style?: any;
};
const InputEmail: React.FC<Props> = (props: Props) => {
  return (
    <Input
      type="email"
      id={props.id}
      className={props.className || ""}
      name={props.name}
      value={props.value || ""}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      onChange={props.changeHandler}
      onClick={props.clickHandler}
      onBlur={props.blurHandler}
      autoFocus={props.autoFocus}
      autoComplete="off"
      style={props.style}
    />
  );
};
export default InputEmail;
