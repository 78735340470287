import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getUsers } from "slices/user";
import PageTitle from "components/PageTitle";
import Table from "components/Table";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const User = () => {
  const dispatch = useAppDispatch();
  const _users = useAppSelector((state) => state.user.entities.users);
  const _usersSort = useAppSelector((state) => state.user.result);
  const users = (_usersSort || []).map((id) => _users[id]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const trs = users.map((user) => {
    const tds = [
      {
        key: "id",
        component: (
          <Text
            text={`${user.id}`}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "name",
        component: (
          <Text
            text={user.name || "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "screenName",
        component: (
          <Text
            text={user.screenName || "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
      {
        key: "email",
        component: (
          <Text
            text={user.email || "-"}
            color={COLORs.textColorRegular}
            weight={FONT_WEIGHT.Bold}
            size={FONT_SIZE.smallText}
          />
        ),
      },
    ];
    return {
      key: user.id,
      tds: tds,
    };
  });

  return (
    <>
      <PageTitle text="ユーザー管理" />
      <div style={{ marginTop: "24px" }}>
        <Table
          ths={[
            { key: "id", name: "ID" },
            { key: "name", name: "ユーザー名" },
            { key: "screenName", name: "スクリーン名" },
            { key: "email", name: "メールアドレス" },
          ]}
          trs={trs}
          styleProps={[
            {
              key: "id",
              style: { minWidth: "40px", maxWidth: "40px" },
            },
            {
              key: "name",
              style: { minWidth: "160px", maxWidth: "160px" },
            },
            {
              key: "screenName",
              style: { minWidth: "160px", maxWidth: "160px" },
            },
            {
              key: "email",
              style: { minWidth: "240px", maxWidth: "240px" },
            },
          ]}
        />
      </div>
    </>
  );
};

export default User;
