import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import admin from "slices/admin";
import adminInvitation from "slices/adminInvitation";
import authorityTemplate from "slices/authorityTemplate";
import auth from "slices/auth";
import user from "slices/user";
import common from "slices/common";

const rootReducer = combineReducers({
  admin: admin,
  adminInvitation: adminInvitation,
  authorityTemplate: authorityTemplate,
  auth: auth,
  user: user,
  common: common,
});

const persistConfig = {
  key: "STATION_ROOT",
  storage,
  //whitelist: [],
  //blacklist: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
