import React from "react";
import { Link } from "react-router-dom";
import Simple from "templates/Simple";
import Text from "components/Text";
import TextLink from "components/TextLink";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const NotFound = () => {
  return (
    <Simple>
      <Text
        text="NotFound"
        color={COLORs.textColorRegular}
        size={FONT_SIZE.heading}
        weight={FONT_WEIGHT.Bold}
      />
      <Link to="/">
        <TextLink text="ログインページへ" />
      </Link>
    </Simple>
  );
};

export default NotFound;
