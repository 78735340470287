import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "store";
import styled from "styled-components";
import Modal from "components/Modal";
import Label from "components/Label";
import InputText from "components/InputText";
import InputCheckbox from "components/InputCheckbox";
import Select from "components/Select";
import {
  getAdminInvitations,
  createAdminInvitation,
} from "slices/adminInvitation";
import { ABILITY_JA } from "shared/constant";
import { addItem, removeItem } from "shared/utility";

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;
const Item = styled.li`
  width: 50%;
  margin-top: 16px;
  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
  }
`;
const ABILITIES = Object.keys(ABILITY_JA) as KeyOf<typeof ABILITY_JA>[];
const EXPIRED_OPTIONS = [
  {
    name: "今日",
    value: dayjs().endOf("day").format(),
  },
  {
    name: "明日",
    value: dayjs().add(1, "day").endOf("day").format(),
  },
  {
    name: "3日間",
    value: dayjs().add(3, "day").endOf("day").format(),
  },
  {
    name: "1週間",
    value: dayjs().add(7, "day").endOf("day").format(),
  },
];
type Props = {
  display: boolean;
  closeHandler: () => void;
};
const InviteModal: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [expiredAt, setExpiredAt] = useState<dayjs.ConfigType>();
  const [authorityTemplateId, setAuthorityTemplateId] = useState<number>();
  const [abilities, setAbilities] = useState<KeyOf<typeof ABILITY_JA>[]>([]);
  const _authorityTemplates = useAppSelector(
    (state) => state.authorityTemplate.entities.authorityTemplates
  );
  const _authorityTemplatesSort = useAppSelector(
    (state) => state.authorityTemplate.result
  );
  const authorityTemplates = (_authorityTemplatesSort || []).map(
    (id) => _authorityTemplates[id]
  );
  const isPresentAuthorityTemplates = authorityTemplates.length > 0;

  useEffect(() => {
    dispatch(getAdminInvitations());
    setExpiredAt(EXPIRED_OPTIONS[3].value);
    if (isPresentAuthorityTemplates) {
      setAuthorityTemplateId(authorityTemplates[0].id);
    }
  }, []);

  useEffect(() => {
    if (authorityTemplateId) {
      setAbilities(
        _authorityTemplates[authorityTemplateId as number].definition.abilities
      );
    }
  }, [authorityTemplateId]);

  const handleReset = () => {
    setEmail("");
    setExpiredAt(EXPIRED_OPTIONS[3].value);
    if (isPresentAuthorityTemplates) {
      setAuthorityTemplateId(authorityTemplates[0].id);
      setAbilities(
        _authorityTemplates[authorityTemplateId as number].definition.abilities
      );
    }
  };

  const handleSubmit = async () => {
    if (!email) {
      alert("メールアドレスを入力してください");
      return;
    }
    const result = await dispatch(
      createAdminInvitation({
        email: email,
        expiredAt: expiredAt,
        abilities: abilities,
      })
    );
    if (result.payload) {
      handleReset();
      props.closeHandler();
    }
  };

  const expiredAtOptions = EXPIRED_OPTIONS.map((option) => {
    return {
      value: option.value,
      text: option.name,
    };
  });

  return (
    <Modal
      display={props.display}
      submitText="確定する"
      cancelText="キャンセル"
      submitHandler={handleSubmit}
      closeHandler={props.closeHandler}
    >
      <div>
        <div>
          <Label text="メールアドレス" htmlFor="email" must />
          <InputText
            name="email"
            value={email}
            changeHandler={(e) => setEmail(e.target.value)}
            placeholder="例. test@station.space"
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <Label text="有効期限" htmlFor="expiredAt" must />
          <Select
            name="expiredAt"
            options={expiredAtOptions}
            value={expiredAt}
            changeHandler={(value) => setExpiredAt(value)}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <Label text="権限テンプレート" htmlFor="authorityTemplateId" must />
          <Select
            name="authorityTemplateId"
            options={authorityTemplates.map((template) => {
              return {
                value: template.id,
                text: template.name,
              };
            })}
            value={authorityTemplateId}
            changeHandler={(value) => setAuthorityTemplateId(value)}
          />
          <List>
            {ABILITIES.map((ability) => {
              const hasAbility = abilities.some((ab) => ab === ability);
              return (
                <Item key={ability}>
                  <InputCheckbox
                    name={ability}
                    checked={hasAbility}
                    changeHandler={() => {
                      const result = hasAbility
                        ? removeItem(abilities, ability)
                        : addItem(abilities, ability);
                      setAbilities(result);
                    }}
                    text={ABILITY_JA[ability]}
                  />
                </Item>
              );
            })}
          </List>
        </div>
      </div>
    </Modal>
  );
};

export default InviteModal;
