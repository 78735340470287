import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import { signUp, signIn } from "httpClient/auth";
import { setAuthHeaders } from "slices/auth";
import { useAppDispatch } from "store";
import Simple from "templates/Simple";
import Label from "components/Label";
import InputText from "components/InputText";
import InputEmail from "components/InputEmail";
import InputPassword from "components/InputPassword";
import Button from "components/Button";
import Text from "components/Text";
import TextLink from "components/TextLink";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Form = styled.form``;
const Item = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
`;
const Aside = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
const Signup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await signUp({
        name: name,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
      });
      if (res.status) handleSignin();
    } catch (err) {
      toast.error("新規登録に失敗しました");
    }
  };

  const handleSignin = async () => {
    const res = await signIn({
      email: email,
      password: password,
    });
    if (res) {
      const headers = res.headers;
      dispatch(
        setAuthHeaders({
          accessToken: headers["access-token"],
          client: headers.client,
          uid: headers.uid,
        })
      );
      navigate("/app");
      toast.success("ログインしました");
    }
  };

  return (
    <Simple>
      <Text
        text="新規登録"
        color={COLORs.textColorRegular}
        size={FONT_SIZE.heading}
        weight={FONT_WEIGHT.Bold}
      />
      <Form onSubmit={handleSignup}>
        <Item>
          <Label text="お名前" htmlFor="name" must />
          <div>
            <InputText
              name="name"
              value={name}
              placeholder="お名前"
              changeHandler={(e) => setName(e.target.value)}
            />
          </div>
        </Item>
        <Item>
          <Label text="メールアドレス" htmlFor="email" must />
          <div>
            <InputEmail
              name="email"
              value={email}
              placeholder="メールアドレス"
              changeHandler={(e) => setEmail(e.target.value)}
            />
          </div>
        </Item>
        <Item>
          <Label text="パスワード" htmlFor="password" must />
          <div>
            <InputPassword
              name="password"
              value={password}
              placeholder="パスワード"
              changeHandler={(e) => setPassword(e.target.value)}
            />
          </div>
        </Item>
        <Item>
          <Label
            text="パスワード（確認用）"
            htmlFor="passwordConfirmation"
            must
          />
          <div>
            <InputPassword
              name="passwordConfirmation"
              value={passwordConfirmation}
              placeholder="パスワード（確認用）"
              changeHandler={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
        </Item>
        <Item>
          <Button
            text="新規登録"
            clickHandler={handleSignup}
            style={{ width: "100%" }}
          />
        </Item>
      </Form>
      <Aside>
        <Link to="/">
          <TextLink text="ログインはこちら" />
        </Link>
      </Aside>
    </Simple>
  );
};

export default Signup;
