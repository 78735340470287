import React from "react";
import styled from "styled-components";
import Svg from "components/Svg";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";
import { ReactComponent as checkIcon } from "assets/images/check.svg";

const WrapLabel = styled.label<{ checked: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  background-color: ${(props) =>
    props.checked ? COLORs.brandColorMain : COLORs.otherColorWhite};
  border: solid 2px
    ${(props) =>
      props.checked ? COLORs.brandColorMain : COLORs.borderColorLight};
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
  `}
`;
const Input = styled.input`
  display: none;
`;
const StyledSvg = styled(Svg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

type Props = {
  name: string;
  checked: boolean;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  text?: string;
};
const InputCheckbox: React.FC<Props> = (props: Props) => {
  return (
    <WrapLabel checked={props.checked} disabled={!!props.disabled}>
      <Input
        type="checkbox"
        id={props.name}
        name={props.name}
        checked={props.checked}
        disabled={!!props.disabled}
        onChange={props.changeHandler}
      />
      <StyledSvg
        component={checkIcon}
        height="16px"
        width="16px"
        fillcolor={COLORs.otherColorWhite}
      />
      {props.text && (
        <Text
          text={props.text}
          color={COLORs.textColorLight}
          size={FONT_SIZE.smallText}
          weight={FONT_WEIGHT.Bold}
          style={{
            whiteSpace: "nowrap",
            marginLeft: "28px",
            lineHeight: "20px",
          }}
        />
      )}
    </WrapLabel>
  );
};
export default InputCheckbox;
