import React from "react";
import Text from "components/Text";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Notification = () => {
  return (
    <Text
      text="WIP | お知らせ管理"
      color={COLORs.textColorXLight}
      weight={FONT_WEIGHT.Bold}
      size={FONT_SIZE.largeText}
    />
  );
};

export default Notification;
