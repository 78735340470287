import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import { ABILITY_JA } from "shared/constant";

export default class AuthorityTemplateRes extends BaseRes {
  @Expose() readonly name: string;
  @Type(() => Definition)
  @Expose()
  definition: Definition;
}
export class Definition {
  @Expose() readonly abilities: KeyOf<typeof ABILITY_JA>[];
}
