import { ClassConstructor } from "class-transformer/types/interfaces";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import DeserializerClient from "./DeserializerClient";
import ClassUtils from "./ClassUtils";
import { camelizeKeys, decamelizeKeys } from "humps";

export default class TypedHttpClient {
  private constructor() {
    // do nothing
  }

  public static async get<T>(
    cls: ClassConstructor<T>,
    path: string,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;
    const instance = this.createInstance();
    try {
      const res = await instance.get(requestConfig.url, {
        ...requestConfig,
        params: decamelizeKeys(requestConfig.params),
      });
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : res.data;
      return ClassUtils.convertToResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async getArray<T>(
    cls: ClassConstructor<T>,
    path: string,
    requestConfig: AxiosRequestConfig = {}
  ) {
    requestConfig.url = path;
    const instance = this.createInstance();
    try {
      const res = await instance.get(requestConfig.url, {
        ...requestConfig,
        params: decamelizeKeys(requestConfig.params),
      });
      const deserializeData = await DeserializerClient.deserialize(res.data);
      return ClassUtils.convertToArrayResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async post<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: any,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;
    requestConfig.data = decamelizeKeys(data);
    const instance = this.createInstance();
    try {
      const res = await instance.post(
        requestConfig.url,
        decamelizeKeys(data),
        requestConfig
      );
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : camelizeKeys(res.data);
      return ClassUtils.convertToResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async postArray<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: any,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;
    requestConfig.data = decamelizeKeys(data);
    const instance = this.createInstance();
    try {
      const res = await instance.post(
        requestConfig.url,
        requestConfig.data,
        requestConfig
      );
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : camelizeKeys(res.data);
      return ClassUtils.convertToArrayResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async put<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: any,
    requestConfig: AxiosRequestConfig = {}
  ) {
    requestConfig.url = path;
    requestConfig.data = decamelizeKeys(data);

    const instance = this.createInstance();
    try {
      const res = await instance.put(requestConfig.url, data, requestConfig);
      const deserializeData = await DeserializerClient.deserialize(res.data);
      return ClassUtils.convertToResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async putArray<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: FormData,
    requestConfig: AxiosRequestConfig = {}
  ) {
    requestConfig.url = path;

    const instance = this.createInstance();
    try {
      const res = await instance.put(requestConfig.url, data, requestConfig);
      const deserializeData = await DeserializerClient.deserialize(res.data);
      return ClassUtils.convertToArrayResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async patch<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: any,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;
    requestConfig.data = decamelizeKeys(data);

    const instance = this.createInstance();
    try {
      const res = await instance.patch(
        requestConfig.url,
        decamelizeKeys(data),
        requestConfig
      );
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : camelizeKeys(res.data);
      return ClassUtils.convertToResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async patchArray<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: any,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;

    const instance = this.createInstance();
    try {
      const res = await instance.patch(
        requestConfig.url,
        decamelizeKeys(data),
        requestConfig
      );
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : camelizeKeys(res.data);
      return ClassUtils.convertToArrayResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async delete<T>(
    cls: ClassConstructor<T>,
    path: string,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;
    const instance = this.createInstance();
    try {
      const res = await instance.delete(requestConfig.url, requestConfig);
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : camelizeKeys(res.data);
      return ClassUtils.convertToResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  public static async deleteArray<T>(
    cls: ClassConstructor<T>,
    path: string,
    _data: FormData,
    requestConfig: AxiosRequestConfig = {}
  ) {
    requestConfig.url = path;

    const instance = this.createInstance();
    try {
      const res = await instance.delete(requestConfig.url, requestConfig);
      const deserializeData = await DeserializerClient.deserialize(res.data);
      return ClassUtils.convertToArrayResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error);
      } else {
        this.handleUnexpectedError(error);
      }
    }
  }

  private static createInstance() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Content-Type": "application/json",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Access-Control-Allow-Origin": process.env.REACT_APP_HOST as string,
        Accept: "application/json",
      },
      withCredentials: true,
    });
  }

  private static handleAxiosError(error: AxiosError<any>) {
    if (error.response) {
      // TODO
    } else if (error.request) {
      // TODO
    }
    throw error;
  }

  private static handleUnexpectedError(error: any) {
    // TODO
    throw error;
  }
}
