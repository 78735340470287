import { useState, useRef, useCallback, useLayoutEffect } from "react";

const useSize = () => {
  const [size, setSize] = useState({ height: 0, width: 0 });
  const ref = useRef<any>();

  const onResize = useCallback(() => {
    if (!ref.current) return;

    const height = ref.current.offsetHeight;
    const width = ref.current.offsetWidth;
    if (height !== size.height || width !== size.width) {
      setSize({ height: height, width: width });
    }
  }, [size.height, size.width]);

  useLayoutEffect(() => {
    if (!ref || !ref.current) return;

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, [ref, onResize]);

  return {
    ref,
    ...size,
  };
};

export default useSize;
