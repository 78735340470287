import React from "react";

type Props = {
  component: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  height?: string;
  width?: string;
  radius?: string;
  clickHandler?: () => void;
  className?: string;
  style?: any;
  fillcolor?: string;
  strokecolor?: string;
};
const Svg: React.FC<Props> = (props: Props) => {
  return (
    <props.component
      radius={props.radius}
      onClick={() => props.clickHandler && props.clickHandler()}
      className={props.className}
      style={{
        ...props.style,
        height: props.height || "initial",
        width: props.width || "initial",
        stroke: props.strokecolor,
        fill: props.fillcolor,
        minHeight: props.height || "initial",
        minWidth: props.width || "initial",
      }}
    />
  );
};
export default Svg;
