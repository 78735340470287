import React from "react";
import styled from "styled-components";
import Text from "components/Text";
import SmartPopup from "components/SmartPopup";
import useSize from "hooks/useSize";
import { COLORs, FONT_SIZE, FONT_WEIGHT } from "shared/constant";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 14px;
  border: solid 1px ${COLORs.borderColor};
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  &:after {
    display: block;
    content: "";
    width: 12px;
    height: 12px;
    border: 2px solid;
    border-color: transparent transparent ${COLORs.borderColor}
      ${COLORs.borderColor};
    transform: rotate(-45deg);
    position: absolute;
    top: 10px;
    right: 14px;
  }
`;
const List = styled.ul``;
const Item = styled.li`
  padding: 4px;
  border-radius: 6px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition-duration: 0.1s;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:hover {
    background-color: ${COLORs.hoverColorDefault};
    p {
      filter: brightness(10%);
    }
  }
`;

type Props = {
  name: string;
  options: {
    value: any;
    text: string;
  }[];
  value: any;
  changeHandler: (value: any) => void;
  placeholder?: string;
};
const Select: React.FC<Props> = (props: Props) => {
  const { ref, width } = useSize();

  const options = props.options.map((option: any, i: number) => {
    return (
      <Item
        key={`option_${props.name}_${i}`}
        onClick={() => {
          props.changeHandler(option.value);
        }}
      >
        <Text
          text={option.text}
          color={COLORs.textColorRegular}
          weight={FONT_WEIGHT.Regular}
          size={FONT_SIZE.regularText}
        />
      </Item>
    );
  });
  const selected = props.options.find(
    (option: any) => option.value === props.value
  );

  return (
    <div ref={ref}>
      <SmartPopup
        trigger={
          <Container>
            {selected ? (
              <Text
                text={selected?.text}
                color={COLORs.textColorRegular}
                weight={FONT_WEIGHT.Regular}
                size={FONT_SIZE.regularText}
              />
            ) : (
              <Text
                text={props.placeholder || "クリックして選択"}
                color={COLORs.textColorLight}
                weight={FONT_WEIGHT.Regular}
                size={FONT_SIZE.smallText}
              />
            )}
          </Container>
        }
        position={["bottom left"]}
        on="click"
        offsetY={4}
        closeOnContentClick
        style={{
          maxHeight: "30vh",
          overflowY: "scroll",
          width: width,
        }}
      >
        {options.length > 0 ? (
          <List>{options}</List>
        ) : (
          <Text
            text="選択肢がありません"
            color={COLORs.textColorXLight}
            weight={FONT_WEIGHT.Regular}
            size={FONT_SIZE.smallText}
          />
        )}
      </SmartPopup>
    </div>
  );
};

export default Select;
